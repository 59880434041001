<template>
  <div class="login page">
    <Nav></Nav>
    <main class="wrap">
      <div class="login-box-wrap">
        <div class="login-img">
          <img src="../assets/ship.png" />
        </div>
        <div class="login-box">
          <div class="login-logo">
            <a href="/"><img src="../assets/logo.png" /></a>
          </div>
          <a-form-model
            id="components-form-demo-normal-login"
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            class="login-form"
          >
            <a-form-model-item ref="name" label="姓名" prop="name">
              <a-input
                v-model="form.name"
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
                placeholder="请输入姓名"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item ref="phone" label="手机号" prop="phone">
              <a-input
                v-model="form.phone"
                @blur="
                  () => {
                    $refs.phone.onFieldBlur();
                  }
                "
                placeholder="请输入手机号"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item has-feedback label="密码" ref="pass" prop="pass">
              <a-input-password
                v-model="form.pass"
                type="password"
                autocomplete="off"
                :loading="false"
                @blur="
                  () => {
                    $refs.pass.onFieldBlur();
                  }
                "
                placeholder="请输入密码"
              />
            </a-form-model-item>
            <a-form-model-item
              has-feedback
              label="确认密码"
              ref="checkPass"
              prop="checkPass"
            >
              <a-input-password
                v-model="form.checkPass"
                type="password"
                autocomplete="off"
                :loading="false"
                @blur="
                  () => {
                    $refs.checkPass.onFieldBlur();
                  }
                "
                placeholder="请输再次输入密码"
              />
            </a-form-model-item>
            <a-form-item :wrapper-col="{ span: 15, offset: 5 }">
              <a-button
                type="primary"
                html-type="submit"
                class="login-form-button"
                @click="onSubmit"
              >
                注册
              </a-button>
            </a-form-item>
          </a-form-model>
          <div class="u-flex">
            <div style="width: 100%;padding-left: 40px;">
              <router-link to="/Register">
                <a-icon type="wechat" style="font-size: 40px; color: #52c41a" />
              </router-link>
            </div>
            <div style="width:200px">
              <router-link to="/Login"> 去登陆
                <a-icon type="arrow-right" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <Foot></Foot>
    </footer>
  </div>
</template>

<script>
import Foot from "@/components/foot.vue";
import Nav from "@/components/Nav.vue";
export default {
  components: {
    Nav,
    Foot,
  },
  data() {
    let mobile = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!/^1[0-9]{10}$/.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    }
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.form.pass) {
        callback(new Error("2次密码输入不一致!"));
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      form: {
        name: "",
        phone: "",
        pass: "",
        checkPass: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 2, max: 50, message: "请输入正确的姓名", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: mobile, trigger: "blur" },
        ],
        pass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "change" },
        ],
        checkPass: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "change" },
        ],
      },
    };
  },
  computed: {},
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.api
            .registered({
              name: this.form.name,
              phone: this.form.phone,
              password: this.form.pass,
              shareid:10,
            })
            .then((res) => {
              this.storage.setItem("token", res.access_token);
              this.storage.setItem("user",null);
              this.getUserInfo();
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.user = res;
          this.storage.setItem("user", res);
          this.$router.push("/user");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #f5f5f5;
}
main {
  //position: relative;
  .login-box-wrap {
    width: 950px;
    height: 480px;
    background: #e8e6ed;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    .login-box {
      background: #ffffff;
      width: 420px;
      height: 460px;
      border-radius: 10px;
      position: absolute;
      top: 10px;
      right: 20px;
      .login-logo {
        text-align: center;
        margin-top: 10px;
      }
      #components-form-demo-normal-login {
        margin: 20px auto;
        width: 90%;
        .login-form {
          max-width: 400px;
        }
        .login-form-forgot {
          float: right;
        }
        .login-form-button {
          width: 100%;
        }
      }
    }
    .login-img {
      width: 480px;
      height: 440px;
      position: absolute;
      left: 26%;
      top: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
footer {
  width: 100%;
  margin-top: 0;
  position: fixed;
  bottom: 0;
}
</style>