var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login page"},[_c('Nav'),_c('main',{staticClass:"wrap"},[_c('div',{staticClass:"login-box-wrap"},[_vm._m(0),_c('div',{staticClass:"login-box"},[_vm._m(1),_c('a-form-model',{ref:"ruleForm",staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-form-model-item',{ref:"name",attrs:{"label":"姓名","prop":"name"}},[_c('a-input',{attrs:{"placeholder":"请输入姓名"},on:{"blur":function () {
                  _vm.$refs.name.onFieldBlur();
                }},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{ref:"phone",attrs:{"label":"手机号","prop":"phone"}},[_c('a-input',{attrs:{"placeholder":"请输入手机号"},on:{"blur":function () {
                  _vm.$refs.phone.onFieldBlur();
                }},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('a-form-model-item',{ref:"pass",attrs:{"has-feedback":"","label":"密码","prop":"pass"}},[_c('a-input-password',{attrs:{"type":"password","autocomplete":"off","loading":false,"placeholder":"请输入密码"},on:{"blur":function () {
                  _vm.$refs.pass.onFieldBlur();
                }},model:{value:(_vm.form.pass),callback:function ($$v) {_vm.$set(_vm.form, "pass", $$v)},expression:"form.pass"}})],1),_c('a-form-model-item',{ref:"checkPass",attrs:{"has-feedback":"","label":"确认密码","prop":"checkPass"}},[_c('a-input-password',{attrs:{"type":"password","autocomplete":"off","loading":false,"placeholder":"请输再次输入密码"},on:{"blur":function () {
                  _vm.$refs.checkPass.onFieldBlur();
                }},model:{value:(_vm.form.checkPass),callback:function ($$v) {_vm.$set(_vm.form, "checkPass", $$v)},expression:"form.checkPass"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 15, offset: 5 }}},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.onSubmit}},[_vm._v(" 注册 ")])],1)],1),_c('div',{staticClass:"u-flex"},[_c('div',{staticStyle:{"width":"100%","padding-left":"40px"}},[_c('router-link',{attrs:{"to":"/Register"}},[_c('a-icon',{staticStyle:{"font-size":"40px","color":"#52c41a"},attrs:{"type":"wechat"}})],1)],1),_c('div',{staticStyle:{"width":"200px"}},[_c('router-link',{attrs:{"to":"/Login"}},[_vm._v(" 去登陆 "),_c('a-icon',{attrs:{"type":"arrow-right"}})],1)],1)])],1)])]),_c('footer',[_c('Foot')],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-img"},[_c('img',{attrs:{"src":require("../assets/ship.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-logo"},[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../assets/logo.png")}})])])}]

export { render, staticRenderFns }